<template>
    <div class="reward">
        <div class="top">
            <div class="flex-box" style="margin-bottom: 0.3rem">
                <div class="item">
                    <p>
                        ￥<span>{{ amount.unable_amount }}</span>
                    </p>
                    <span>未入账</span>
                </div>
                <div class="item">
                    <p>
                        ￥<span>{{ amount.use_amount }}</span>
                    </p>
                    <span>已入账</span>
                </div>
            </div>
            <div class="flex-box">
                <router-link to="/applypromoter?types=3">奖励说明</router-link>
                <a href="javascript:void(0);" @click="checkAllMoney">全部到账</a>
            </div>
        </div>
        <!-- 列表 -->
        <team-order-list :list="list" :isLoad="isLoad" :loadLastText="loadMore" @getMore="getRewardOrder">
        </team-order-list>
    </div>
</template>

<script>
    import {
        getReward,
        getRewardOrder,
        applyReward
    } from "@/http/api";
    import TeamOrderList from "@/components/teamOrder/TeamOrderList";
    export default {
        components: {
            TeamOrderList
        },
        data() {
            return {
                userToken: "",
                amount: {},
                list: [],
                isLoad: false,
                loadMore: true,
                pages: 1
            };
        },
        mounted() {
            this.userToken = this.$LStorage.getItem("userToken").user_token;
            this.getReward();
            this.getRewardOrder();
        },
        methods: {
            // 升级奖励金额
            async getReward() {
                const res = await getReward({
                    data: {
                        user_token: this.userToken
                    }
                });
                this.amount = res.data;
            },
            // 升级奖励列表
            async getRewardOrder() {
                const res = await getRewardOrder({
                    data: {
                        user_token: this.userToken,
                        pages: this.pages
                    }
                });
                this.isLoad = true;
                if (res.code == "200") {
                    this.list = this.list.concat(res.data);
                    if (res.data.length < res.numPage) {
                        this.loadMore = false;
                    } else {
                        this.pages++;
                        this.loadMore = true;
                    }
                }
            },
            // 升级奖励金额金额到账
            async applyReward() {
                const res = await applyReward({
                    data: {
                        user_token: this.userToken
                    }
                });
                if (res.code == "200") {
                    this.$toast.success("操作成功");
                    this.amount.use_amount = "0.00";
                } else {
                    this.$toast.fail(res.msgs);
                }
            },
            checkAllMoney() {
                if (this.userInfo.referrer_lv == 1) {
                    this.$toast("没有权限");
                } else if (this.userInfo.referrer_lv == 2) {
                    if (this.amount.use_amount && Number(this.amount.use_amount > 0)) {
                        this.applyReward();
                    } else {
                        this.$toast("当前资金不足");
                    }
                }
            }
        }
    };
</script>

<style lang='scss' soped>
    .reward {
        background: #f6f6f6;
        font-size: 0.3rem;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;

        .top {
            width: 100vw;
            height: 3.2rem;
            padding: 0.5rem 0;
            background: #ff5400;
            color: #fff;
            // margin-bottom: 4vw;
            box-sizing: border-box;

            .flex-box {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 100%;

                a {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 5px;
                    border: 1px solid #fff;
                    color: #fff;
                    font-size: 0.28rem;
                    width: 26vw;
                    height: 9.4vw;
                }

                .item {
                    font-size: 0.24rem;
                    text-align: center;

                    >p {
                        font-size: 0.24rem;

                        span {
                            font-size: 0.48rem;
                        }
                    }
                }
            }
        }

        .list-box {
            width: 100%;
            height: calc(100vh - 3.2rem);
            padding-top: 0.2rem;
            box-sizing: border-box;
            overflow-y: auto;
        }
    }
</style>
